import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'ECconvert'
})

export class EcConvertPipe implements PipeTransform {
    
    
    transform(value: any, ...args: any[]) {
        let rate = 2.7021611;

        return (value * rate).toFixed(2);
    }
}