import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcConvertPipe } from './pipes/ec-convert.pipe';



@NgModule({
  declarations: [EcConvertPipe],
  imports: [
    CommonModule
  ],
  exports: [
    EcConvertPipe
  ]
})
export class CoreModule { }
